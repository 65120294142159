import * as dompack from "dompack";

export default class Logger
{
  constructor(node, options)
  {
    this.loggingfrag = document.createDocumentFragment();

    // insert the logitems which were created before the DOM was ready to accept our logitems
    dompack.onDomReady(() =>
    {
      var logcontainer = document.querySelector(".panel--log");
      if(logcontainer)
        logcontainer.appendChild(this.loggingfrag);

      //this.add("info", "onDomReady");
    });
  }

  formatTime(date)
  {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    return          (hours < 10 ? "0"+hours : hours)
            + ":" + (minutes < 10 ? "0"+minutes : minutes)
            + ":" + (seconds < 10 ? "0"+seconds : seconds);
  }

  add(type, logcontent)
  {
    var logcontainer = document.getElementById("admin__status__log");

    let logdatenode = dompack.create("span", { textContent: this.formatTime(new Date()), className: "logitem__when" });
    let logtextnode = dompack.create("span", { textContent: logcontent, className: "logitem__what" });

    var logitem = document.createElement("div");
    logitem.className = "logitem";
    logitem.appendChild(logdatenode);
    logitem.appendChild(logtextnode);

    if (logcontainer)
      logcontainer.appendChild(logitem);
    else
      this.loggingfrag.appendChild(logitem);

    console.log(logcontent);
  }

  addHTML(type, logcontent)
  {
    var logcontainer = document.getElementById("admin__status__log");

    let logdatenode = dompack.create("span", { textContent: this.formatTime(new Date()), className: "logitem__when" });
    let logtextnode = dompack.create("span", { innerHTML: logcontent, className: "logitem__what" });

    var logitem = document.createElement("div");
    logitem.className = "logitem";
    logitem.appendChild(logdatenode);
    logitem.appendChild(logtextnode);

    if (logcontainer)
      logcontainer.appendChild(logitem);
    else
      this.loggingfrag.appendChild(logitem);

    console.log(logcontent);
  }

}
