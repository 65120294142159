
/** @short get the index of the first object within the array which contains the specified value (or one of the specified values if an array is passed)
    @param value can be a single value or array of values. if an array was specified, a single matching value within the specified array is enough for an object to match.
*/
Array.prototype.indexByProperty = function(key, value)
{
  var arrlen = this.length;

  if (value instanceof Array)
  {
    for(var idx=0; idx<arrlen; idx++)
    {
      if (value.indexOf(this[idx][key]) > -1)
        return idx;
    }
  }
  else
  {
    for(var idx=0; idx<arrlen; idx++)
    {
      if(this[idx][key] === value)
        return idx;
    }
  }

  return -1;
}

/** @short get the first object within the array which contains the specified value (or one of the specified values if an array is passed)
    @param value can be a single value or array of values. if an array was specified, a single matching value within the specified array is enough for an object to match.
*/
Array.prototype.getByProperty = function(key, value)
{
  var idx = this.indexByProperty(key,value);
  return idx >= 0 ? this[idx] : null;
}


Array.prototype.filterByPropertyContains = function(key, value)
{
  var arrlen = this.length;
  var matches = [];

  for(var idx=0; idx<arrlen; idx++)
  {
    //if (this[idx][key].length > 0)
    //  console.log(idx, this[idx][key], (this[idx][key].indexOf(value) > -1 ? "does contain" : "does not contain"), value);

    if (this[idx][key].indexOf(value) > -1)
      matches.push(this[idx]);
  }

  return matches;
}

/** @short return an array with all the objects in which the specified property contains the value (or one of the values in case an array was given as value)
    @param value can be a single value, array of values or a function.
           If an array was specified, a single matching value within the specified array is enough for an object to match.
           If an function was specified ....
*/
Array.prototype.filterByProperty = function(key, value)
{
  var arrlen = this.length;
  var matches = [];

  if (value instanceof Array)
  {
    for(var idx=0; idx<arrlen; idx++)
    {
      if (value.indexOf(this[idx][key]) > -1)
        matches.push(this[idx]);
    }
  }
  else if (typeof value == "function")
  {
    return this.filter(function(obj, idx)
    {
      return value(obj[key], idx, obj);
    });
  }
  else
  {
    for(var idx=0; idx<arrlen; idx++)
    {
      if(this[idx][key] === value)
        matches.push(this[idx]);
    }
  }

  return matches;
}
