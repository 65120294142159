require('font-awesome/css/font-awesome.css');
require('@typopro/web-open-sans/TypoPRO-OpenSans.css');
require('./src/components/noto-serif/fonts.css');

import "./beurzenformulier-cssvars.scss";
import "./src/site.scss";
import "./src/controls-buttons.scss";
import "./src/controls-misc.scss";
import "./src/controls.scss";
import "./shared/forms/pulldown.scss";
import "./src/forms.scss";
import "./src/dialogs.scss";
import "./src/registration__personal.scss";
import "./src/registration__interests.scss";
import "./src/registration__summary.scss";
import "./src/admin.scss";
import "./src/column.scss";

import "./src/jstools";

import * as pwalib from '@mod-publisher/js/pwa';
import * as whintegration from '@mod-system/js/wh/integration';
import SpellcoderLogger       from "./src/components/logger";
window.logger = new SpellcoderLogger();

import SpellcoderCheckboxList from "./src/components/checkboxlist";
import SpellcoderTabstrip     from "./src/components/tabstrip";
import RegistrationSyncApi    from "./src/sync_api.es";
//import addToLog from "./sync_api.es";

import Pulldown from "@mod-system/js/dompack/components/pulldown";

import "./beurzenformulier.lang.json";

// Webhare modules
import * as dompack from "dompack";
import JSONRPC from "@mod-system/js/net/jsonrpc";
import { getTid, convertElementTids } from "@mod-tollium/js/gettid";
import { isValidEmailAddress } from "dompack/types/email";

// 3th party modules
import Tooltip from "tooltip.js";

let isadminapp = !!whintegration.config.obj.pwasettings;

//window.addToLog = addToLog;

/*

ADDME:
- check after data update if an new version is available
- version info
- more logging
- always show a title for events in the admin (EN > NL > DE ??)
- toon datum in titel

ADDME: decide:
- show bachelor/master for selected programmes (or maybe only if selected ba+ma programmes with the same name) ??

NOTES:
- we don't have Dutch translations for the form, we only support English and German

localStorage.rb_beurzen_appsettings <-- appsettings
localStorage.rb_beurzen_cache <-- events, programmes, ... -->

*/

let appsettings =
  { lang:            "en"
  , selectedeventid: ""
  };

let appcache =
  { events:          []
  , programmes:      []
  , nationalities:   []
  , diplomas:        []
  };

let supportedlanguages = ["de", "en", "nl"];

let dialog_submitted_closeafter = 5 * 1000;
let dialog_login_closeafter = 30 * 1000;

let ping_interval = 20 * 1000;
let ping_timeout  = 10 * 1000;

let currentevent = null;

let debug = true;

let registrationform = null; // node
let programmetabs =  null;
let programmelists = [];

let rpc = new JSONRPC( { url:"/wh_services/radboud/fairs" }); //+ debug_addition })

// use the same API on a different name so we can specifically log StoreRegistration RPC calls
let rpc_register = new JSONRPC( { url:"/wh_services/radboud/fairs_register" }); //+ debug_addition })
let syncapi = new RegistrationSyncApi(rpc_register, { onupdate: onQueueUpdate });

/* expose for debugging */
window.syncapi = syncapi;
window.appsettings = appsettings;
window.appcache = appcache;

window.ping_currenteventdata = null;


/*

We need to use custom pulldowns because ALL dropdown fail on PWA's on iOS
after switching from the PWA to another app and returning.
After doing this the hide/show animation for the dropdown gets stuck.
(This started in iOS 15 which changed the dropdowns and still persists in iOS 16.1)

https://gitlab.webhare.com/radboud/radboud-home/-/issues/64

Related bugreports:
- https://bugs.webkit.org/show_bug.cgi?id=238318
- https://feedbackassistant.apple.com/feedback/9960855 (only visible for Mark)

*/
dompack.register("select", node => new Pulldown(node, 'custom-pulldown'));
/*
export function refreshSelect(select)
{
  dompack.dispatchCustomEvent(select, "refresh", { bubbles    : true
                                                 , cancelable : false
                                                 });
}
*/

async function checkForAppUpdate()
{
  console.log("Admin: starting update check");
  let updateres = await pwalib.checkForUpdate();
  console.log("Admin: update check result", updateres);

  if(!updateres.needsupdate)
  {
    logger.add("log", "Application is up-to-date");
    return;
  }

  logger.add("log", "An update is available, starting download");
  await pwalib.downloadUpdate();
  pwalib.updateApplication();
}

function onQueueUpdate()
{
  //console.info("onQueueUpdate");
  var stats = syncapi.getStatistics();


  // Find all registrations in the storage to check if the queued registrations are for multiple events
  // (to prevent confusion on why the X queued registrations(of any event) + X registrations on server for this event != total leads for this event)
  var eventids = [];
  var registrationscount_currentevent = 0;
  for (let idx = 0; idx < localStorage.length; idx++)
  {
    var key = localStorage.key(idx);

    if (key.substr(0,15) == "rb_beurzen_reg_")
    {
      //var keystring = key.substr(15);
      //var keynr = parseInt(keystring, 10);
      //queue.push(keynr);
      var regdata = JSON.parse(localStorage[key]);

      // Add the event if we didn't see it yet
      if (eventids.indexOf(regdata[0]) == -1)
        eventids.push(regdata[0]);

      if (regdata[0] == currentevent.dynamics_id)
        registrationscount_currentevent++;
    }
  }

  //console.log("Registrations for", eventids.length, "events found.");


  var inqueue = document.getElementById("admin__leads_inqueue");
  if(inqueue)
    inqueue.innerText = stats.queuedregistrations + (eventids.length > 1 ? " (for multiple events)" : "");

  var ltotal = document.getElementById("admin__leads_total");
  if(ltotal)
    if (window.ping_currenteventdata)
      ltotal.innerText = window.ping_currenteventdata.registrations + " on server and " + registrationscount_currentevent + " waiting to be sent";
    else
      ltotal.innerText = "?";
}


dompack.register("body", (node, idx) =>
{
  // prevent elastic scroll
  // NOTE: in the future we might be able to use CSS property overscroll-behavior: none; for this
  document.body.addEventListener("ontouchmove", function(evt) { evt.preventDefault(); });
});


dompack.register(".page--registration", (node, idx) =>
{
  node.addEventListener("click", doDelegateRegistrationClickEvents);

  registrationform = document.querySelector("form.page--registration");
  window.regform = registrationform; // DEBUG


  var tabstripnode = document.querySelector(".registration__interests__tabstrip");
  programmetabs = new SpellcoderTabstrip(tabstripnode);

  window.programmetabs = programmetabs; // DEBUG

  registrationform.education.addEventListener("change", updateYearOptionsByEducation);

  registrationform.askquestion.addEventListener("change", onAskQuestionChange);
  registrationform.addEventListener("submit", onRegistrationFormSubmit);

  refreshCompleteUI(); // admin & regform
});//, { afterdomready: true }); // easyer


dompack.register("#dialog-login", (node, idx) =>
{
  //document.getElementById("logindialog__password").
  node.querySelector("form").addEventListener("submit", onSubmitPassword);
});

function appEntryPoint()
{
  // if (location.href.indexOf("futurecommunication") == -1)
  //   document.querySelector(".registration__furthercomm").style.display = "none";

  if(isadminapp)
  {
    verifyLocalstorageIsWritable();
    readAppSettings();
    readDataCache();
  }
  else
  {
    //read settings from the integration variable
    appcache = whintegration.config.obj.appcache;
    appsettings.selectedeventid = appcache.events[0].dynamics_id;
  }

  document.body.addEventListener("click", doDelegateClickEvents);

  // force the reinitialize into the event selected in a previous session
  if (appsettings.selectedeventid != "")
  {
    if(isadminapp)
      document.getElementById("admin__event").value = appsettings.selectedeventid;
    setSelectedEvent(appsettings.selectedeventid);
  }

  // if there wasn't a selected event or it could not be set
  // (not existing in the data anymore) go to the admin panel
  if (currentevent === null)
  {
    refreshTitles();
    showPasswordDialog();
    //switchPage("admin"); // FIXME: or go through a password here too?
  }
  if(document.querySelector(".panel--log"))
    document.querySelector(".panel--log").addEventListener("click", doHandleLogClicks);

  document.querySelector(".page--registration__resetbutton").addEventListener("click", resetRegistrationForm);

  dompack.qSA(".ctabutton--next").forEach(_ => _.addEventListener("click", evt => gotoPage(evt, +1)));
  dompack.qSA(".ctabutton--previous").forEach(_ => _.addEventListener("click", evt => gotoPage(evt, -1)));

  onQueueUpdate();
}

function doHandleLogClicks(evt)
{
  var restartbtn = dompack.closest(evt.target, ".logbutton--restart");
  if (restartbtn)
  {
    window.location.reload(true);
  }
}




function showPasswordDialog()
{
  openDialog("dialog-login", { timeout: dialog_login_closeafter, canclose: true});

  let pwdfield = document.getElementById("logindialog__password");
  pwdfield.value = "";
  pwdfield.focus();

  // iOS workaround against it trying to scroll to the password field (but to the wrong ypos)
  let bounds = document.querySelector("#dialog-login .dialog__body").getBoundingClientRect();
//alert(bounds.top);
  window.scrollTo(0, bounds.top - 50); // minus a little to account for the iOS browser chrome
}
function onSubmitPassword(evt)
{
  evt.preventDefault();

  if (document.getElementById("logindialog__password").value == "aapnootmies")
  {
    switchPage("admin");

    let pwdfield = document.getElementById("logindialog__password");
    pwdfield.blur(); // so the virtual keyboard closes

    closeDialog("dialog-login");
  }
  else
    alert("Incorrect password.");
}




// seperate onready so in case a new version of the app crashes due missing fields in
// data in the cache, the new data will still be fetched (hopefully fixing the former problem of missing fields in the data)
if(isadminapp)
  dompack.onDomReady(doGetDataUpdate);


dompack.register(".langselector", (node, idx) =>
{
  node.addEventListener("click", onLangSelectionClick)
});



function refreshCompleteUI()
{
  refreshAdmin();
  refreshRegistrationFormUI();
}

function setLanguageCode(langcode)
{
  appsettings.lang = langcode.toLowerCase();
  saveAppSettings();
  refreshAdmin();
  refreshRegistrationFormUI();
}

// FIXME: use tabstrip for language selection too?
function onLangSelectionClick(evt)
{
  var langnode;
  if (evt.target.classList.contains(".langselector__lang"))
    langnode = evt.target;
  else
    langnode = dompack.closest(evt.target, ".langselector__lang");

  if (!langnode)
    return; // clicking within some whitespace between or around the language buttons

  langnode.classList.add("selected");

  var langcode = langnode.getAttribute("data-lang");
  setLanguageCode(langcode);
}

dompack.register(".column__langselector__select", node => node.addEventListener("input", () => setLanguageCode(node.value)));

function readDataCache()
{
  if (debug)
    console.log("readDataCache");

  if (!("rb_beurzen_cache" in localStorage))
  {
    console.info("No rb_beurzen_cache in localStorage yet.");
    return;
  }

  try
  {
    let data = JSON.parse(localStorage.rb_beurzen_cache);
    if (!data)
      console.error("App cache data empty?"); // FIXME: does this happen?
    else
      appcache = data;
  }
  catch(err)
  {
    console.info("Corrupt cache in localStorage");
  }
}
function saveDataCache()
{
  localStorage.rb_beurzen_cache = JSON.stringify(appcache);
}



function readAppSettings()
{
  if(!isadminapp)
    throw new Error("appsettings not available in plain mode");

  if (debug)
    console.log("readAppSettings");

  if (!("rb_beurzen_appsettings" in localStorage))
  {
    console.info("No rb_beurzen_appsettings in localStorage yet.");
    return;
  }

  try
  {
    let settings = JSON.parse(localStorage.rb_beurzen_appsettings);
    if (settings)
      appsettings = settings;
    else
      console.error("Empty settings");
  }
  catch(err)
  {
    console.info("Corrupt appsettings in localStorage");
  }

  console.info(appsettings);
}

function saveAppSettings()
{
  if(!isadminapp)
    return;

  localStorage.rb_beurzen_appsettings = JSON.stringify(appsettings);
}









let __oldpagename = "";
let __oldpagenode = null;

function switchPage(pagename)
{
  console.log("** switchPage", pagename);

  var pagenodes = document.querySelectorAll("[data-pagename]");
  for (let node of pagenodes)
  {
    var currentpn = node.getAttribute("data-pagename");
    if (currentpn == pagename)
      node.classList.add("page--active");
    else
      node.classList.remove("page--active");
  }
}



/*****************************************************

User Interface - Admin

*****************************************************/

let can_reach_server;


dompack.register(".page--admin", (node, idx) =>
{
  document.querySelector(".admin__savebtn").addEventListener("click", doSaveAdminSettings);

  document.querySelector(".admin__status__closebutton").addEventListener("click", doSwitchToRegistrationForm);

  document.getElementById("updatebutton").addEventListener("click", doGetDataUpdate);

  scheduleInternetConnectionCheck();
});

function scheduleInternetConnectionCheck()
{
  setInterval(checkInternetConnection, ping_interval);
}

function checkInternetConnection()
{
  //console.log("Sending a ping...");
  var reqobj = rpc.request("Ping"
             , [ currentevent ? currentevent.dynamics_id : "" ]
             , function(result) // success
               {
                 //console.log("Ping got pong", result);

                 can_reach_server = result && result.success;
                 updateInternetConnectionStatus();

                 // If we havn't switched to another event since we sent the ping
                 // when can use the statistics we received
                 if (currentevent && result.eventinfo && result.eventinfo.dynamics_id == currentevent.dynamics_id)
                 {
                   window.ping_currenteventdata = result.eventinfo;
                   onQueueUpdate();
                 }
                 else
                  console.warn("Ping contains info from another event.");
               }
             , function(result) // error
               {
                 //console.log("Ping failed");

                 // let's just assume we have no internet
                 can_reach_server = false;
                 updateInternetConnectionStatus();
               }
            , { timeout: ping_timeout }
            );
}

function updateInternetConnectionStatus()
{
  var statusnode = document.getElementById("admin__internetstatus");

  if (can_reach_server)
    statusnode.innerText = getTid("radboud:beurzenformulier.admin.onlinestatus-online");
  else if (navigator.onLine)
    statusnode.innerText = getTid("radboud:beurzenformulier.admin.onlinestatus-cannotreachserver");
  else
    statusnode.innerText = getTid("radboud:beurzenformulier.admin.onlinestatus-offline");
}

function doSwitchToRegistrationForm()
{
  switchPage("registrationform");
}

function refreshAdmin()
{
  if(!isadminapp)
    return;

  console.info("RefreshAdmin\nEvents:", appcache.events);

  var eventselect = document.getElementById("admin__event");
  replaceSelectOptionsWithDomVals(eventselect, appcache.events, { key: "dynamics_id", orderalpha: true });
  //gettid.getTid("radboud:beurzenformulier.admin.field-event");
}

function replaceSelectOptionsWithDomVals(selectnode, items, options) // selecttext, orderalpha)
{
  let finoptions =
      { selecttext: "" // initial placeholder/"please select" text
      , orderalpha: false
      , key:        ""
      };
  if (options)
    finoptions = { finoptions, ...options}

  /*
  console.info("replaceSelectOptionsWithDomVals", options);
  console.dir(items);
  */

  if (!items)
  {
    console.error("Didn't get items for", selectnode);
    return;
  }

  if (finoptions.orderalpha)
    items.sort(sortByName);

  var currentvalue = selectnode.value;

  selectnode.innerHTML = ""; // destroy all old options

  // Add a dummy option to prevent the user for (accidently)
  // skipping selecting the correct value
  let option = dompack.create('option', { value: "", textContent: finoptions.selecttext });
  selectnode.appendChild(option);

  var eventselect = document.getElementById("admin__event");
  for(let item of items)
  {
    let textc = item.names[appsettings.lang];
    if(item.formatted_eventstart)
      textc += ` (${item.formatted_eventstart[appsettings.lang]})`;

    // make German fall back to Dutch if no translation was provided in German
    if (textc == "" && appsettings.lang == "de")
      textc = item.names["nl"];

    option = dompack.create('option', { value:item[options.key], textContent: textc });
    selectnode.appendChild(option);
  }

  // restore the value
  selectnode.value = currentvalue;
}

function sortByName(a, b)
{
  //console.log(a.names[appsettings.lang], b.names[appsettings.lang])

  if(a.names[appsettings.lang] == b.names[appsettings.lang])
  {
    if(a.names[appsettings.lang] == b.names[appsettings.lang])
      return 0;

    return (a.names[appsettings.lang] < b.names[appsettings.lang]) ? -1 : 1;
  }
  return (a.names[appsettings.lang] < b.names[appsettings.lang]) ? -1 : 1;
}

function sortByTitle(a, b)
{
  if(a.title == b.title)
    return 0;

  return a.title < b.title ? -1 : 1;
}



function doSaveAdminSettings()
{
  var event = document.getElementById("admin__event").value;

  if (!event)
  {
    // FIXME: liever automatisch een beurs kiezen tenzij er geen enkele is
    alert("Kies a.u.b. een beurs.");
    return;
  }

  setSelectedEvent(event);

  saveAppSettings();

  switchPage("registrationform");
}

function setSelectedEvent(eventid)
{
  var event = appcache.events.getByProperty("dynamics_id", eventid)
  if (!event)
  {
    console.warn("Cannot select event", eventid, " (event has ended?)");
    return;
  }

  appsettings.selectedeventid = eventid;

  currentevent = event;

  console.info("Currentevent now set to", currentevent);

  // Is the active language not available for this event?
  if (event.allowlanguages.indexOf(appsettings.lang.toUpperCase()) == -1)
  {
    console.group();
    console.warn("Language ", appsettings.lang, " is not available for this event.");
    console.info("Allowed are: ", event.allowlanguages);
    console.info("App support: ", supportedlanguages);

    // FIXME: base on guest country (NL in NL, anders Engels)
    if (event.allowlanguages.indexOf("EN") > -1)
      appsettings.lang = "en";
    else
    {
      // find the first allowed language which is also supported by this version of the form
      let langfound = false;
      for (let lang of event.allowlanguages)
      {
        lang = lang.toLowerCase();
        if (supportedlanguages.indexOf(lang) > -1)
        {
          langfound = true;
          appsettings.lang = lang;
          break;
        }
      }

      // still no match? whatever, we need a language so force English
      if (!langfound)
      {
        console.log("No language match")
        appsettings.lang = "en";
      }
    }

    console.log("Language forced to", appsettings.lang);
    console.groupEnd();
  }
  //logger.add("info", "setting event to "+eventid+"/"+event.names.en);


  // ADDME: check whether the current language is available...
  //        OR set the language to the default language for this event

  refreshRegistrationFormUI();
  resetRegistrationForm();
}



/*****************************************************

User Interface - Registration form

*****************************************************/

let __successive_logo_clicks = 0;

function onAskQuestionChange()
{
  var questioncontainer = document.querySelector(".registration__question");
  if (registrationform.askquestion.checked)
  {
    questioncontainer.classList.add("question--active");
    registrationform.question.setAttribute("required", "");
  }
  else
  {
    questioncontainer.classList.remove("question--active");
    registrationform.question.removeAttribute("required");
  }
}

function getEmail()
{
  //return registrationform.email_entity.value + "@" + registrationform.email_host.value;
  return registrationform.email.value;
}

function onRegistrationFormSubmit(evt)
{
  evt.preventDefault();

  // Make sure virtual keyboard closes on tablets
  // so you can see which form field has an error
  document.activeElement.blur();

  let commtextnode = document.querySelector('.allowfurthercommunication__permissiontext');

  let isdutchbachelor = stateIsDutchBachelor();
// FIXME: if NL selected clear "nationality", "phone", "startmoment"


  let isvalid = validateForm(registrationform);
  console.log("ValidateForm says isvalid = ", isvalid);

  if (!isvalid)
  {
    console.log("Registration submit cancelled (invalid).");
    registrationform.classList.add("attemptedsubmit");
    return;
  }

  let formdata =
    { programmes:  getAllSelectedProgrammeIds()
    , firstname:   registrationform.firstname.value
    , infix:       isdutchbachelor ? registrationform.infix.value : ""
    , lastname:    registrationform.lastname.value

    , email:       getEmail()

    , question:    registrationform.askquestion.checked ? registrationform.question.value : ""

    , allowfurthercommunication:     registrationform.allowfurthercommunication.checked
    , allowfurthercommunicationtext: commtextnode ? commtextnode.textContent : ""

    // These field are only asked when NOT "NL"
    , phone:          isdutchbachelor ? "" : registrationform.phone.value
    , nationality:    isdutchbachelor ? "" : registrationform.nationality.value
    , startmoment:    isdutchbachelor ? "" : registrationform.startmoment.value

    // These fields are only asked when "NL"
    , education:      isdutchbachelor ? registrationform.education.value     : ""
    , educationyear:  isdutchbachelor ? registrationform.educationyear.value : ""
    , version: 2

    // Not used anymore:
    //, gender:      registrationform.gender[0].checked ? 1 : 2 // SF workaround
    //, diploma:     registrationform.diploma ? registrationform.diploma.value : "" // USE IF ACTIVATED IN THE HTML (temp disabled by request)
    };
  console.log("Registration", formdata);


  //rpc.request("StoreRegistration", [ appsettings.selectedeventid, formdata, appsettings.lang ], onRegistrationSubmitResult);
  syncapi.queueRegistration([ appsettings.selectedeventid, formdata, appsettings.lang ]);

  openDialog("dialog-submitted", { timeout: isadminapp ? dialog_submitted_closeafter : undefined, canclose: isadminapp });
  if(isadminapp) //then recycle the form. not for single-user use, as they'll see the reset during transition
    resetRegistrationForm();
}


function validateForm(form)
{
  var formvalid = true;

  if (!stateIsDutchBachelor())
  {
    //check phone number
    let phonenumberfield = dompack.qS(form,'#form-phone');
    if(phonenumberfield)
    {
      let phonenumber = phonenumberfield.value;
      let phonevalid = true;
      if(phonenumber || phonenumberfield.required)
      {
        //remove odd characters, remove initial zero
        let fixedphonenumber = phonenumber.replace(/[^0-9]/g,'');
        if(fixedphonenumber.substr(0,1)=='0')
          fixedphonenumber = fixedphonenumber.substr(1);

        let countrycode = dompack.qSA('#form-phone-countrycode option').filter(node=>node.selected)[0];
        dompack.qS("#form-final-phone").value = "+" + countrycode.value.substr(1) + " " + fixedphonenumber;
        phonevalid = fixedphonenumber.length >= parseInt(countrycode.dataset.minlength)
                     && fixedphonenumber.length <= parseInt(countrycode.dataset.maxlength);
      }
      else
      {
        dompack.qS("#form-final-phone").value="";
      }

      dompack.qS(form, "#form-phone").classList[phonevalid?"remove":"add"]("invalid");

      if(!phonevalid)
        formvalid = false;
    }
  }

  if(form.querySelector('input[name=programme]')) //a programme is in the selected aprt
  {
    if(getAllSelectedProgrammeIds().length == 0)
    {      formvalid = false;
      alert(getTid("radboud:beurzenformulier.registration.selectprogramme"));
      return false;
    }
  }

  // loop all fields
  for (let field of dompack.qSA(form, 'input,textarea,select'))
  {
    if(field.name == 'programme')
      continue; //validated above..

    //ignore phonenumber, we did that above
    if(field.id == "form-phone")
      continue;

    /*
    Single email field
    use this if we want to use custom email validation instead of the native browser validation
    */
    if (field.id == "form-email")
    {
      let emailinvalid = !isValidEmailAddress(getEmail());
      if(emailinvalid)
        formvalid = false;

      registrationform.email.classList.toggle("invalid", emailinvalid);

      continue;
    }


/*
    if(field.id == "form-email-entity")
    {
      let emailinvalid = !isValidEmailAddress(getEmail());
      if(emailinvalid)
        formvalid = false;

      registrationform.email_entity.classList.toggle("invalid", emailinvalid);
      registrationform.email_host.classList.toggle("invalid", emailinvalid);
      continue;
    }
*/

    // native browser check
    field.checkValidity();
    if (field.validity.valid)
    {
      // remove error styles and messages
    }
    else
    {
      console.log("Invalid: ", field, field.value);
      // style field, show error, etc.
      // form is invalid
      formvalid = false;
    }
  }

  // cancel form submit if validation fails
  //if (!formvalid) {
  //  if (event.preventDefault) event.preventDefault();
  //}
  return formvalid;
}

function gotoPage(evt, dir)
{
  dompack.stop(evt);

  let pages = dompack.qSA(".page__column");
  let curpage = dompack.qS(".page__column.page__column--current");
  if(dir < 0) //backwards
  {
    let gotopage = Math.max(0,pages.indexOf(curpage) - 1);
    setPage(pages[gotopage]);
    return;
  }

  //forwards...
  registrationform.classList.add("attemptedsubmit");
  if(validateForm(curpage))
  {
    let gotopage = Math.min(pages.length - 1, pages.indexOf(curpage) + 1);
    setPage(pages[gotopage]);
  }
  else
  {
    let firstfailed = curpage.querySelector(':invalid,.invalid');
    if(firstfailed)
      firstfailed.focus();
  }
}

function setPage(pagenode)
{
  dompack.qSA(".page__column").forEach(node => node.classList.toggle("page__column--current", node === pagenode));
}

/** @short reset the registrationform
    @long initialize the form with all settings for the currentevent
*/
function resetRegistrationForm()
{
  registrationform.classList.remove("attemptedsubmit");
  registrationform.reset();
  setPage(dompack.qS("#registration__personal"));
  updateRegistrationSummary();

  // prefill the nationality based on the event's settings
  var nationality;
  if (currentevent.nationalityprefill)
    nationality = appcache.nationalities.getByProperty("id", currentevent.nationalityprefill);
  if (nationality)
    registrationform.nationality.value = nationality.dynamics_id;

/*
  registrationform.email_entity.classList.remove("invalid");
  registrationform.email_host.classList.remove("invalid");
*/

  let selectcountry = dompack.qSA("#form-phone-countrycode option").filter(node => node.dataset.country == currentevent.hostcountry)[0];

  if(!selectcountry)
    selectcountry = dompack.qSA("#form-phone-countrycode option").filter(node => node.dataset.country == "NLD")[0];

  if(selectcountry)
    selectcountry.selected = true;

  onAskQuestionChange();
}

function refreshRegistrationFormUI()
{
  refreshTitles();

  if (!currentevent)
  {
    console.warn("refreshRegistrationFormUI cannot complete because there's no active event.");
    return;
  }

  //allowlanguages
  if (currentevent.allowlanguages)
    updateLanguageSetting();

// 17-oct-2022 - Disabled requirement to have a phone number by request
//  document.getElementById("form-phone").required = appsettings.lang == 'en';

  // Set the language code on <html> so the stylesheet may switch between logo's
  document.documentElement.setAttribute("lang", appsettings.lang);

  // refresh registration options
  replaceSelectOptionsWithDomVals(registrationform.nationality, appcache.nationalities, { orderalpha: true, key: "dynamics_id" });

//  if (registrationform.diploma)
//    replaceSelectOptionsWithDomVals(registrationform.diploma,     appcache.diplomas);

  replaceSelectOptionsWithDomVals(registrationform.startmoment, appcache.startmoments, { key: "dynamics_id" });
  replaceSelectOptionsWithDomVals(registrationform.education,   appcache.educations,   { key: "dynamics_id" });


  refreshProgrammeLists();

  if (currentevent.formatted_eventstart)
    dompack.qSA(".registration__date").forEach(_ => _.textContent = currentevent.formatted_eventstart[appsettings.lang]);

  dompack.qSA(".registration__eventname, .admin__status__eventname").forEach(_ => _.textContent = currentevent.names[appsettings.lang]);

  updateRegistrationFormFieldVisibility();
  updateYearOptionsByEducation();
}


function stateIsDutchBachelor()
{
  return appsettings.lang == "nl"; // FIXME: and .... bachelor ..?;
}

function updateRegistrationFormFieldVisibility()
{
  console.log(appsettings);
  // targetaudience
  let dutch_bachelor = stateIsDutchBachelor();

  // !! Make sure to update onRegistrationFormSubmit() when rules about visibility of fields change.
  setFieldgroupVisibility(".fieldgroup--infix",          dutch_bachelor);
  setFieldgroupVisibility(".fieldgroup--phone",         !dutch_bachelor);
  setFieldgroupVisibility(".fieldgroup--nationality",   !dutch_bachelor);
  setFieldgroupVisibility(".fieldgroup--startmoment",   !dutch_bachelor);

  setFieldRequired(registrationform.nationality,        !dutch_bachelor);

  setFieldgroupVisibility(".fieldgroup--education",      dutch_bachelor);
  updateYearOptionsByEducation();

  setFieldRequired(registrationform.startmoment,   !dutch_bachelor);
  setFieldRequired(registrationform.education,      dutch_bachelor);
}
function setFieldRequired(fieldnode, required)
{
  if (required)
    fieldnode.setAttribute("required", "");
  else
    fieldnode.removeAttribute("required");
}

function setFieldgroupVisibility(fgclass, visible)
{
  // console.log(fgclass, visible);
  let node = document.querySelector(fgclass);
  // console.log(node);
  node.classList[visible ? "remove" : "add"]("wh-form__fieldgroup--hidden");
}


function updateYearOptionsByEducation()
{
  let tag_edu = registrationform.education.value;

  if (!stateIsDutchBachelor())
  {
    setFieldgroupVisibility(".fieldgroup--educationyear", false);
    setFieldRequired(registrationform.educationyear, false);
    return;
  }

  let education;
  if (tag_edu != "")
  {
    education = appcache.educations.getByProperty("dynamics_id", tag_edu);
    console.info("Selected", tag_edu, education);
  }

  if (!education || education.yearoptions.length == 0)
  {
    setFieldgroupVisibility(".fieldgroup--educationyear", false);
    setFieldRequired(registrationform.educationyear, false);
    registrationform.educationyear.innerHTML = ""; // clear all options
  }
  else
  {
    setFieldgroupVisibility(".fieldgroup--educationyear", true);
    setFieldRequired(registrationform.educationyear, true);
    replaceSelectOptionsWithDomVals(registrationform.educationyear, education.yearoptions, { key: "dynamics_id" });
  }
}

function updateLanguageSetting()
{
  console.log("Event allows these languages", currentevent.allowlanguages, appsettings.lang);

  dompack.qS(".column__langselector").hidden = !(currentevent?.allowlanguages?.length > 1);
  dompack.qS(".column__langselector__select").replaceChildren(...currentevent?.allowlanguages?.map(lang => <option value={lang.toLowerCase()}>{lang.toUpperCase()}</option>));
  dompack.qS(".column__langselector__select").value = appsettings.lang;

  var langnodes = document.querySelectorAll(".langselector__lang");
  for (let node of langnodes)
  {
    var lang = node.getAttribute("data-lang");
    if (currentevent.allowlanguages.indexOf(lang.toUpperCase()) == -1)
      node.classList.add("disabled");
    else
      node.classList.remove("disabled");

    if (lang == appsettings.lang)
      node.classList.add("selected");
    else
      node.classList.remove("selected");
  }

}

function refreshTitles()
{
  console.log("refreshing titles and options for language", appsettings.lang);

  if (window.__tooltip)
    window.__tooltip.dispose();

  getTid.tidLanguage = appsettings.lang;
  convertElementTids(document.body);

  //let askquestionlabel = document.querySelector('label[for="allowfurthercommunication"] > .label__text');
  let askquestionlabel = document.getElementById("askquestionlabel");
  //let txt = askquestionlabel.innerText;
  //console.info("Before", txt);
  //txt = txt.replace("[linkstart]", '<span class="explainfuturecommunication">');
  //txt = txt.replace("[linkend]", '</span>');
  //console.info("After", txt);
  //askquestionlabel.innerHTML = txt;
  //askquestionlabel.innerHTML = `<label for="allowfurthercommunication">Allow further communication</label>`;

  let options = { trigger: "click" //"hover focus"
                , placement: "top"
                , title: "Whe'll keep you posted on:<br /><ul><li>Changes in programmes</li><li>Events such as open days</li></ul>"
                , html: true
                /*
                , popperOptions: { data: { styles: { width: "250px", color: "#00A" } } // FIXME: styles not picked up??
                                 //, onCreate: function(evt) { console.log(evt); evt.preventDefault(); }
                                 }
                */
                };
  //console.info(options);

  let node = registrationform.querySelector('.explainfuturecommunication');
  if(node)
    window.__tooltip = new Tooltip(node, options);

  dompack.qSA('#form-phone-countrycode option').forEach(_ => _.textContent = _.getAttribute("data-title-" + appsettings.lang));
}

function getCategorizedProgrammesForEvent()
{
  let itemlists = { BACHELOR: []
                  , MASTER:   []
                  , OTHER:    []
                  };

  listitemloop:
  for(let programme of appcache.programmes)
  {
    // skip programmes which aren't relevant to this event
    if (currentevent.programme.indexOf(programme.id) == -1)
      continue;

    let title = programme.names[appsettings.lang];

    if (programme.dynamics_id == "")
    {
      console.error("Programme has no dynamics_id!!");
      continue;
    }

    //////////////////////////////////////////////////////////////////////////
    // Find any programme which has us as a parent
    let tracks = appcache.programmes.filterByPropertyContains("parents", programme.id);

    let subitems = [];

    /*
    if (tracks.length > 0)
      console.info(programme.names.en + "has "+tracks.length+" tracks", programme, tracks);
    */

    for(let track of tracks)
    {
      if (currentevent.programme.indexOf(track.id) == -1)
        continue;
      subitems.push({ rowkey: track.dynamics_id
                    , title:  track.names[appsettings.lang]
                    , type:   track.typename
                    })
    }
    subitems.sort(sortByTitle);

    //////////////////////////////////////////////////////////////////////////

    let newitem = { rowkey:   programme.dynamics_id
                  , title:    title
                  , subitems: subitems
                  , type:     programme.typename
                  };

    if (programme.typename in itemlists)
      itemlists[programme.typename].push(newitem);
    else if (["MASTERSPECIALIZATION", "BACHELORSPECIALISATIE"].indexOf(programme.typename) > -1)
    {
      /*
      NOTE: A specialization can belong to multiple programmes.
            In case none of the programmes it can belong to are selected for this event,
            we must present the specialization as programme.
      */
      // console.log("Track parents", programme.parents);

      //let tracks = appcache.programmes.getByAnyInProperty("id", programme.id);
      for (let parentprogrammeid of programme.parents)
      {
        if (currentevent.programme.indexOf(parentprogrammeid) > -1)
        {
          // console.log("Parent programme", parentprogrammeid, "is available");
          continue listitemloop;
        }
        //console.log("Looking if parent programme", parentprogrammeid, "is available");
      }

      // If none of the parent programmes for this specialization exist in the list for this event,
      // present the specialization at the programme level.
      if (programme.typename == "MASTERSPECIALIZATION")
        itemlists["MASTER"].push(newitem);
      else if (programme.typename == "BACHELORSPECIALISATIE")
        itemlists["BACHELOR"].push(newitem);
    }
    else
    {
      // 'PhD' en 'Radboud Summer School' vallen in category "other"
      itemlists.OTHER.push(newitem);
    }
  }

  for (let list of Object.values(itemlists))
    list.sort(sortByTitle);

  return itemlists;
}

function refreshProgrammeLists()
{
  if (debug)
    console.log("refreshProgrammeLists");

  programmelists = [];

  //console.info("appcache.programmes", appcache.programmes);

  var programmelistnodes = document.querySelectorAll(".programmeslist");

  var first_visible_tab;

  let itemlists = getCategorizedProgrammesForEvent();

  for (let proglistnode of programmelistnodes)
  {
    if (!proglistnode.__init)
    {
      //console.log("INIT", proglistnode);
      proglistnode.addEventListener("checkboxlist_change", updateRegistrationSummary);
      proglistnode.__init = true;
    }

    let programtype = proglistnode.getAttribute("data-programtype");
    if (!(programtype in itemlists))
    {
      console.error("Cannot find", programtype, "for programlist node", proglistnode);
      console.log("Itemlist:", itemlists);
      continue;
    }

    let items = itemlists[programtype];

    //console.log("Programme list of type", programtype, "has", items.length, "items");
    //console.log(items);

    var tabpanel = dompack.closest(proglistnode, ".tabpanel");
    var associated_tab = document.querySelector('[data-tabpanel="' + tabpanel.id + '"]')

    var panel_is_empty = items.length == 0;

    /*
    console.log({ tabbutton: associated_tab
                , tabpanel:  tabpanel
                , programmelist: proglistnode
                });
    */

    //tabpanel.classList.toggle("disabled", panel_is_empty);
    tabpanel.classList[panel_is_empty ? "add" : "remove"]("disabled");

    if (associated_tab)
      //associated_tab.classList.toggle("disabled", panel_is_empty);
      associated_tab.classList[panel_is_empty ? "add" : "remove"]("disabled");

    if (!panel_is_empty)
    {
      if (!first_visible_tab)
        first_visible_tab = associated_tab;

      var plist = new SpellcoderCheckboxList(proglistnode
            , { items:     items
              , onchange:  updateRegistrationSummary
              , inputname: "programme"
              });
      programmelists.push(plist);
    }
  }

  if (!programmetabs.selectedtab || programmetabs.selectedtab.classList.contains("disabled"))
    programmetabs.selectTab(first_visible_tab);

  //console.info(first_visible_tab);
}

function getAllSelectedProgrammeIds()
{
  var allprogrammeids = [];

//  console.group("programmelist values");
  for (let list of programmelists)
  {
    let programmeids = list.getValue();
/*
    if (debug)
      console.info(list.container.getAttribute("data-programtype"), programmeids);
*/
    allprogrammeids = allprogrammeids.concat(programmeids);
  }
//  console.groupEnd();

  return allprogrammeids;
}

function updateRegistrationSummary()
{
  let allprogrammeids = getAllSelectedProgrammeIds();
  let items = appcache.programmes.filterByProperty("dynamics_id", allprogrammeids);
  items.sort(sortByName);

  var frag = document.createDocumentFragment();

  for (let item of items)
  {
    //console.log(item);
    let row = document.createElement("div");
    row.className = "selection__item";
    row.setAttribute("data-id", item.dynamics_id);

    let label = document.createElement("div");
    label.className = "selection__item__title";
    label.innerText = item.names[appsettings.lang];
    row.appendChild(label);

    let closebutton = document.createElement("div");
    closebutton.className = "selection__item__closebutton";
    row.appendChild(closebutton);

    frag.appendChild(row);
  }

  var summarynode = document.querySelector(".registration__selection");
  summarynode.innerHTML = "";
  summarynode.appendChild(frag);
}



function openDialog(node, {canclose,timeout} = {})
{
  //console.log("OPEN DIALOG", node, timeout);

  if (typeof(node) == "string")
    node = document.getElementById(node);

  if (!node)
    console.info("Cannot find dialog node");

  if (timeout)
    node.__autoclosetimer = setTimeout(closeDialog, timeout, node);

  node.querySelector('.dialog__closebutton').style.display = canclose ? "block" : "none";
  node.classList.add("dialog--active");
}

function closeDialog(node)
{
  //console.log("CLOSE DIALOG", node);

  if (typeof(node) == "string")
    node = document.getElementById(node);

  if (!node)
    console.info("Cannot find dialog node");

  clearTimeout(node.__autoclosetimer);
  node.classList.remove("dialog--active");

  if (document.activeElement)
    document.activeElement.blur();
}


function doDelegateRegistrationClickEvents(evt)
{
  if (window.__tooltip)
  {
    if (   !dompack.closest(evt.target, ".explainfuturecommunication")
        && !dompack.closest(evt.target, ".tooltip"))
      window.__tooltip.hide();
  }

     doCheckForLogoClick(evt)
  || doCheckForSummaryitemRemoveButton(evt);
}

function doDelegateClickEvents(evt)
{
  doCheckForDialogCloseButton(evt)
}


function doCheckForLogoClick(evt)
{
  var logonode = document.getElementById("logo");
  //console.log(evt.target, logonode);

  //if (evt.target != logonode)
  if (!evt.target.classList.contains("ru_logo"))
  {
    __successive_logo_clicks=0;
    return false;
  }

  evt.preventDefault();

  __successive_logo_clicks++;
  //console.log(__successive_logo_clicks);

  if (__successive_logo_clicks == 5)
  {
    //switchPage("admin");
    showPasswordDialog();
    __successive_logo_clicks = 0;
  }

  return true; // handled the event
}

function doCheckForDialogCloseButton(evt)
{
  var dcb = dompack.closest(evt.target, ".dialog__closebutton");
  if (dcb)
  {
    var dialog = dompack.closest(dcb, ".dialog");
    closeDialog(dialog);
    return true;
  }
}

function doCheckForSummaryitemRemoveButton(evt)
{
  var closebutton = dompack.closest(evt.target, ".selection__item__closebutton");
  if (!closebutton)
    return false;

  let summaryitem = dompack.closest(evt.target, ".selection__item");
  let id_to_remove = summaryitem.getAttribute("data-id");

  // NOTE: In case of a specialization it might show up under multiple programmes
  //       So we must uncheck the specialization under all programmes
  let checknodes = document.querySelectorAll('input[value="' + id_to_remove + '"]');

  console.log({ summaryitem:  summaryitem
              , id_to_remove: id_to_remove
              , checknodes:   checknodes
              });

  for(let checknode of checknodes)
    checknode.checked = false;

  // force update.. FIXME: cleaner to let the checkboxlist class handle disabling
  updateRegistrationSummary();

  return true;
}



/*****************************************************

Misc

*****************************************************/

async function doGetDataUpdate()
{
  if(!isadminapp)
    throw new Error("doGetDataUpdate unavailable on visitorform");

  checkForAppUpdate();
  logger.add("log", "Requesting updated events & programmes");

  let lock = dompack.flagUIBusy();

  try
  {
    let returneddata = rpc.async("GetFairsAndProgrammes_PWA");
    //lets get the result to resolve now
    returneddata = await returneddata;
    // received updated lists of events, programmes and domain values (nationaliteit, diplomas, prior_education's)

    if (debug)
      console.info("Received updated data", returneddata);

    logger.add("log", "Received updated events & programmes");

    appcache = returneddata;

    saveDataCache();
    refreshCompleteUI();
  }
  catch(e)
  {
    console.log(e);
    logger.add("log", "Could not communicate with the server to received updated events & programmes");
  }
  finally
  {
    lock.release();
  }

}

function verifyLocalstorageIsWritable()
{
  // in admin mode we need to store data in the localStorage,
  // which we cannot do in privacy mode
  try
  {
    localStorage.rb_beurzen_writetest = "";
  }
  catch (err)
  {
    if ((err.name).toUpperCase() == 'QUOTA_EXCEEDED_ERR')
      alert("Please make sure Safari's 'Privacy Mode' is disabled and local storage is enabled.");
    else
      alert("Please make sure your browser isn't in private browsing mode and local storage is enabled.");
  }
}


/*****************************************************

Application Cache debugging

*****************************************************/

function onDownloading(evt)
{
  console.log("Downloading", evt);
}

function onUpdateReady(evt)
{
  logger.addHTML("info", 'Beursformulier update downloaded (<div class="logbutton logbutton--restart">restart</div>).');
/*
  new $wh.Popup.Dialog( { title:   getTid("admin.msg_update_download_title")
                        , text:    getTid("admin.msg_update_download")
                        , buttons: [ //{ title: getTid("common.ok"), cancel: true }
                                     // FIXME: can also do location.reload(true) or does it try to break cache/appcache??
                                     { title: "restart", onClick: function() { window.location.href = window.location.href; } }
                                   ]
                        });
*/
}


function onClickExplainFutureCommunication()
{
  openDialog("dialog-explainfuturecommunication", { canclose: true });
}

if(isadminapp) //looks like we're the main PWA form
  pwalib.onReady(appEntryPoint, { reportusage: true });
else
  dompack.onDomReady(appEntryPoint);
