import * as dompack from "dompack";

export default class SpellcoderTabstrip
{
  constructor(node, options)
  {
    if (options)
      this.setOptions(options);

    if (typeof(node) == "string")
      this.container = document.getElementById(node);
    else
      this.container = node;

    this.selectedtab = null;

    if (!this.container)
    {
      console.error("No container.");
      return;
    }

    this.container.addEventListener("click", this.onSelectTab.bind(this));

    //this.refresh();
  }


  /** @param value tabnode. null to deselect all tabs.
  */
  selectTab(tabnode)
  {
    if (tabnode && !tabnode.classList.contains("tabstrip__tab"))
    {
      console.error("Not a valid tab:", tabnode);
      return;
    }

    this.selectedtab = tabnode;

    let tabnodes = this.container.querySelectorAll(".tabstrip__tab");
    for(let node of tabnodes)
    {
      var associated_panelid = node.getAttribute("data-tabpanel");
      var associated_panel = document.getElementById(associated_panelid);

      if (!associated_panel)
      {
        console.log("Missing panel associated to a tab");
        continue;
      }


      // cannot use classList.toggle due to IE
      if (node == tabnode)
      {
        node.classList.add("selected");
        associated_panel.classList.add("selected");
      }
      else
      {
        node.classList.remove("selected");
        associated_panel.classList.remove("selected");
      }
    }
  }

  onSelectTab(evt)
  {
    var tabnode;
    if (evt.target.classList.contains(".tabstrip__tab"))
      tabnode = evt.target;
    else
      tabnode = dompack.closest(evt.target, ".tabstrip__tab");

    if (!tabnode)
      return;

    evt.preventDefault();
    this.selectTab(tabnode);
  }


  setOptions(options)
  {
    this.items = options.items;
  }
}
