import * as dompack from "dompack";


let cblistidx = 0;

export default class SpellcoderCheckboxList
{
  constructor(node, options)
  {
    cblistidx++;
    this.listitemidx = 0;

    var domoptions = dompack.getJSONAttribute(node, "data-checkboxlist-options");

    this.options =
            { debug: true
            , inputname: ""
            };//, domoptions, options;

    if (domoptions)
      this.setOptions(domoptions);

    if (options)
      this.setOptions(options);

    if (typeof(node) == "string")
      this.container = document.getElementById(node);
    else
      this.container = node;

    if (!this.container)
    {
      console.error("No container.");
      return;
    }

    this.container.addEventListener("click", this.doToggleTreeBranch);
    this.container.addEventListener("touchstart", this.doToggleTreeBranch);

    this.refresh();
  }

  doToggleTreeBranch(evt)
  {
    let toggle = dompack.closest(evt.target, ".checkboxlist__branchtoggle");
    if (!toggle)
      return;

    let branch = dompack.closest(evt.target, ".canexpand");
    if (!branch) // this is not a toggleable item with sub items
      return;

    // this node should contain the subitems
    let branchcontent = branch.nextElementSibling;
    if (!branchcontent.classList.contains("checkboxlist__subitems"))
      return; // someone deleted it from the DOM ??

    evt.preventDefault();

    let expand = !branch.classList.contains("expanded");
    console.log("New expand state:", expand);
    branch.classList[expand?"add":"remove"]("expanded");
    branchcontent.classList[expand?"add":"remove"]("expanded");

    if (expand)
      branchcontent.style.height = branchcontent.scrollHeight + "px";
    else
      branchcontent.style.height = "0";
  }


  setOptions(options)
  {
    this.items = options.items;
    delete options.items;

    Object.assign(this.options, options);
  }

  getValue()
  {
    let checkboxes = this.container.querySelectorAll(".checkboxlist__item input");
    let rowkeys = [];
    for (let node of checkboxes)
    {
      if (node.checked)
        rowkeys.push(node.value);
    }
    return rowkeys;
  }
/*
  setSelectionFor(value, checked)
  {

  }
*/
  refresh()
  {
    var frag = document.createDocumentFragment();
    this.generateItemsInto(frag, this.items);
    this.container.innerHTML = "";
    this.container.appendChild(frag);
  }

  __fireChangeEvent()
  {
    //dompack.dispatchCustomEvent(this, "checkboxlist_change", { bubbles:false, cancelable:false, detail: { }});    

    if (this.options.onchange)
      this.options.onchange();
  }

  generateItemsInto(container)
  {
    this.__generateItemsInto(container, this.items);
  }

  __generateItemsInto(container, items)
  {
    for (let item of items)
    {
      this.listitemidx++;

      //let checkbox_id = "cb_"+this.options.inputname+"_"+item.rowkey;
      let checkbox_id = "cb_" + cblistidx + "_" + this.listitemidx;
      let hassubitems = "subitems" in item && item.subitems.length > 0;

      let row = document.createElement("div");
      row.className = "checkboxlist__item";

      row.setAttribute("data-type", item.type);

      let checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.value = item.rowkey;
      checkbox.id = checkbox_id;
      checkbox.addEventListener("change", this.__fireChangeEvent.bind(this));

      if (this.options.inputname)
        checkbox.name = this.options.inputname;

      let visualcheckboxnode = document.createElement("label");
      visualcheckboxnode.setAttribute("for", checkbox_id);

      let titlenode = document.createElement("label");
      titlenode.className = "checkboxlist__item__title";
      titlenode.textContent = item.title;
      titlenode.setAttribute("for", checkbox_id);

      row.appendChild(checkbox);
      row.appendChild(visualcheckboxnode); // container for custom-styled component + textual content
      row.appendChild(titlenode);

      // give all rows a toggle so the flex container are easyer to layout
      let toggle = document.createElement("div");
      toggle.className = "checkboxlist__branchtoggle";
      row.appendChild(toggle);

      container.appendChild(row);

      if (hassubitems)
      {
        /*
        console.log(row, "has", item.subitems.length, "subitems");
        console.log(item.subitems);
        */

        row.classList.add("canexpand");

        let subitems_wrapper = document.createElement("div");
        subitems_wrapper.className = "checkboxlist__subitems";
        this.__generateItemsInto(subitems_wrapper, item.subitems);
        container.appendChild(subitems_wrapper);
      }
    }
  }

}