import * as dompack from "dompack";

/*
Used keys in localStorage

- rb_beurzen_clientid
- rb_beurzen_regcount - always counting up to have some kind of unique id
- rb_beurzen_reg_<id>
- rb_beurzen_regsyncqueue         - array with indexes which havn't been synced yet


Empty the queue:

delete localStorage.rb_beurzen_regcount;
delete localStorage.rb_beurzen_regsyncqueue;


FIXME: detect keychange on syncqueue key, so stats will be updated
       if another tab is flushing the registration queue too


ADDME:
- reimplement verificationcode (correct registration synced?)
- reimplement clientid ?
- reimplement local date and ipaddress??



// WARNING: Must activate product mode in .debug
// ... Google Tagmanager can hinder the app?


*/

export default class RegistrationSyncApi
{
  constructor(rpc, options)
  {
    logger.add("info", "RegistrationSyncApi init");

    this.rpc = rpc;

    this.options =
        { registration_sync_timeout: 10 * 1000
        , registration_retry_delay:  15 * 1000
        , onupdate:                  null
        };

    if (options)
      Object.assign(this.options, options);

    if (options.onupdate)
      this.options.onupdate = options.onupdate;


    this.registrations_sync_retry_timer = null;
    this.registrations_sync_call = null;
    this.lastseenformbuild = null;
    this.lastseenformtimestamp = null;

    /*
    if (!("rb_beurzen_clientid" in localStorage))
    {
      // don't go beyond 32 bit signed integer, or the Webhare RPC will think it's a FLOAT instead of INTEGER
      //localStorage.rb_beurzen_clientid = Math.round(Math.random()*2147483647);
      localStorage.rb_beurzen_clientid = Math.round(Math.random()*214748364);
      console.info("new clientid: "+localStorage.rb_beurzen_clientid);
    }
    else
      console.info("clientid: "+localStorage.rb_beurzen_clientid);
    */

    let qresult = this.reconstructQueue();

    if (!("rb_beurzen_regcount" in localStorage))
    {
      console.info("Initializing registration queue");
      logger.add("info", "Initializing registration queue");
      localStorage.rb_beurzen_regcount = qresult.nextregistrationid;
    }

    this.registration_sync_reschedule();

    //setTimeout(0, this.__fireQueueUpdate());
  }

  __fireQueueUpdate()
  {
    //this.fireEvent("queueupdate");
    if (this.options.onupdate)
      this.options.onupdate();
  }


  reconstructQueue()
  {
    // find all registration entries in the localStorage
    var queue = [];
    var nextregistrationid = 0;
    for (let idx = 0; idx < localStorage.length; idx++)
    {
      var key = localStorage.key(idx);

      if (key.substr(0,15) == "rb_beurzen_reg_")
      {
        var keystring = key.substr(15);
        var keynr = parseInt(keystring, 10);
        queue.push(keynr);

        if (keynr > nextregistrationid)
          nextregistrationid = keynr;
      }
    }
    console.log("Freshly reconstructed queue:", queue);

    localStorage.rb_beurzen_regsyncqueue = JSON.stringify(queue);

    return { queue: queue
           , nextregistrationid: nextregistrationid
           };
  }

  queueRegistration(registrationdata)
  {
    //var newindex = localStorage.rb_beurzen_regcount.toInt() + 1;
    var newindex = parseInt(localStorage.rb_beurzen_regcount) + 1;
    localStorage.rb_beurzen_regcount = newindex;

    //registrationdata = this.enrichRegistrationData(registrationdata);

    localStorage["rb_beurzen_reg_"+newindex] = JSON.stringify(registrationdata);

    this.sync_addToQueue(newindex); // notify which index hasn't been synced yet

    console.log("Adding registration #", newindex);
    logger.add("info", "Added registration #"+newindex+" to the queue. (" + registrationdata[1].email) + ")";

    this.attemptToSyncRegistrations();

    this.__fireQueueUpdate();
  }

  attemptToSyncRegistrationsOnTimer()
  {
    this.registrations_sync_retry_timer = null;
    this.attemptToSyncRegistrations();
  }

  registration_sync_reschedule()
  {
    //logger.add("info", "registration_sync_reschedule");

    if (!this.registrations_sync_retry_timer)
      //this.registrations_sync_retry_timer = this.attemptToSyncRegistrationsOnTimer.delay(this.options.registration_retry_delay, this);
      this.registrations_sync_retry_timer = setTimeout(this.attemptToSyncRegistrationsOnTimer.bind(this), this.options.registration_retry_delay);
  }

  sync_startNewQueue()
  {
    console.info("!!!!!!! sync_setupQueue");
    logger.add("Starting a new registration queue");

    var queue = [];
    localStorage.rb_beurzen_regsyncqueue = JSON.stringify(queue);
  }

  sync_addToQueue(itemindex)
  {
    console.info("sync_addToQueue");

    // add the ID of the new registration to the queue of registrations which must be send
    var queue = JSON.parse(localStorage.rb_beurzen_regsyncqueue);
    queue.push(itemindex);
    localStorage.rb_beurzen_regsyncqueue = JSON.stringify(queue);

    console.log("Queue:", localStorage.rb_beurzen_regsyncqueue);
  }

  sync_getFirstQueueItemIndex()
  {
    console.info("sync_getFirstQueueItemIndex");

    var queue = JSON.parse(localStorage.rb_beurzen_regsyncqueue);
    if (queue.length > 0)
      return queue[0];
    else
      return -1;
  }

  sync_removeFromQueue()
  {
    console.info("sync_removeFromQueue");

    var queue = JSON.parse(localStorage.rb_beurzen_regsyncqueue);
    var indexnr = queue.shift();
    localStorage.rb_beurzen_regsyncqueue = JSON.stringify(queue);
  }

  sync_pushToBackOfQueue()
  {
    console.info("sync_pushToBackOfQueue");

    var queue = JSON.parse(localStorage.rb_beurzen_regsyncqueue);

    //console.log(queue);

    var indexnr = queue.shift();
    queue.push(indexnr);
    localStorage.rb_beurzen_regsyncqueue = JSON.stringify(queue);

    console.log(queue);
  }

  attemptToSyncRegistrations()
  {
    this.registration_sync_reschedule();

    var queue = JSON.parse(localStorage.rb_beurzen_regsyncqueue);
    if (queue.length == 0)
    {
      //console.log("Nothing to sync");
      return;
    }

    // FIXME: we only want to block in case our sync is pending, not for other types of communication
    //if (rpc.isRequestPending())
    if (this.registrations_sync_call != null)
    {
      console.log("Previous RPC call still pending")
      logger.add("info", "A registration is already being sent, will try again soon.");
      return;
    }

    var itemindex = queue[0];

    var keyname = "rb_beurzen_reg_"+itemindex;

    if (!(keyname in localStorage))
    {
      console.warn("rb_beurzen_reg_ #"+itemindex+" missing.");
      return;
    }

    var registrationdata = JSON.parse(localStorage[keyname]);

    logger.add("info", "Sending registration #"+itemindex + " (" + registrationdata[1].email) + ")";
    console.log("Sending registration #"+itemindex, registrationdata);

     /*[ registrationdata.fairtag
     , ""// OBSOLETE // was used to create events clientside
     , parseInt(localStorage.rb_beurzen_clientid || 0)
     , Math.round(new Date().getTime() / 1000)
     , registrationdata
     ]*/

    this.registrations_sync_call = this.rpc.request( "StoreRegistration"
                                     , registrationdata
                                     , this.onRegistrationSyncSuccess.bind(this, itemindex)
                                     , this.onRegistrationSyncFailure.bind(this, itemindex)
                                     , { timeout: this.options.registration_sync_timeout
                                       }
                                     );
  }

  onRegistrationSyncFailure(itemindex)
  {
    // FIXME: if the fail is called before this.rpc.request finished initialization this doesn't work:
    //this.registrations_sync_call.cancel(); // cancel request
    this.registrations_sync_call = null;

    //console.warn("rb_beurzen_reg_ sync failed: couldn't connect or script error");
    this.sync_pushToBackOfQueue(); // whe'll get around to retry later
    logger.add("error", "Sending registration #"+itemindex+" failed - Couldn't connect or script error.");
  }

  sync_removeRegistrationFromQueue(regid)
  {
    console.info("sync_removeRegistrationFromQueue", regid);

    var queue = JSON.parse(localStorage.rb_beurzen_regsyncqueue);
    queue = queue.filter(myval => myval != regid); // remove the id from the array
    localStorage.rb_beurzen_regsyncqueue = JSON.stringify(queue);
  }

  onRegistrationSyncSuccess(itemindex, result)
  {
    this.registrations_sync_call = null;

    if (!result.success)
    {
      logger.add("error", "Sending registration #"+itemindex+" failed - Registration refused with error: " + result.reason);
      //console.error(result.error_full);

      if (result.debug)
        console.dir(result.debug);

      return;
    }

    var keyname = "rb_beurzen_reg_"+itemindex;
    if (!(keyname in localStorage))
    {
      console.warn("rb_beurzen_reg_"+itemindex+" missing.");
      return;
    }

    var registrationdata = JSON.parse(localStorage[keyname]);

    // result.reg.id == WRD_ID of registration
    console.groupCollapsed("Synced registration #"+itemindex + " (" + registrationdata[1].email) + ")";
    console.log("Keyname:", keyname);
    console.dir("Server response", result);
    console.groupEnd();

    logger.add("info", "Registration #"+itemindex+" succesfully sent.");

    delete localStorage[keyname];
    this.sync_removeRegistrationFromQueue(itemindex);

    this.__fireQueueUpdate();
  }

  getStatistics()
  {
    return { totalregistrations:  parseInt(localStorage.rb_beurzen_regcount, 10)
           , queuedregistrations: JSON.parse(localStorage.rb_beurzen_regsyncqueue).length
           };
  }
};
