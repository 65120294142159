/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

let appname;

export function setAppName(newappname) {
  appname = newappname;
}

export function getAppName() {
  return appname;
}
