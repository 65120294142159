@font-face {
  font-family: 'Noto Serif';
  font-weight: 400;
  font-style: normal;
  src: local('Noto Serif'),
       local('Noto-Serif-regular'),
       url('Noto-Serif-regular.woff2') format('woff2'),
       url('Noto-Serif-regular.woff') format('woff'),
       url('Noto-Serif-regular.ttf') format('truetype'),
       url('Noto-Serif-regular.svg#NotoSerif') format('svg');
}

@font-face {
  font-family: 'Noto Serif';
  font-weight: 700;
  font-style: normal;
  src: local('Noto Serif Bold'),
       local('Noto-Serif-700'),
       url('Noto-Serif-700.woff2') format('woff2'),
       url('Noto-Serif-700.woff') format('woff'),
       url('Noto-Serif-700.ttf') format('truetype'),
       url('Noto-Serif-700.svg#NotoSerif') format('svg');
}

@font-face {
  font-family: 'Noto Serif';
  font-weight: 400;
  font-style: italic;
  src: local('Noto Serif Italic'),
       local('Noto-Serif-italic'),
       url('Noto-Serif-italic.woff2') format('woff2'),
       url('Noto-Serif-italic.woff') format('woff'),
       url('Noto-Serif-italic.ttf') format('truetype'),
       url('Noto-Serif-italic.svg#NotoSerif') format('svg');
}

@font-face {
  font-family: 'Noto Serif';
  font-weight: 700;
  font-style: italic;
  src: local('Noto Serif Bold Italic'),
       local('Noto-Serif-700italic'),
       url('Noto-Serif-700italic.woff2') format('woff2'),
       url('Noto-Serif-700italic.woff') format('woff'),
       url('Noto-Serif-700italic.ttf') format('truetype'),
       url('Noto-Serif-700italic.svg#NotoSerif') format('svg');
}

